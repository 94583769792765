<template>
  <div class="setting__style__wrapper">
    <p>
      Отключая некоторые функции стиля, будет улучшена производительность на
      слабых ПК и смартфонах.
    </p>
    <div class="anim__check">
      <h4>Анимированный фон</h4>
      <div class="check">
        <div class="button-paral-cov">
          <div class="button r" id="button-paral">
            <input
              type="checkbox"
              class="checkbox"
              v-model="anim"
              @change="func_change_anim"
            />
            <div class="knobs-cov">
              <span></span>
            </div>
            <div class="layer-cov"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="anim__check">
      <h4>Blur фона всплывающих окон</h4>
      <div class="check">
        <div class="button-paral-cov">
          <div class="button r" id="button-paral">
            <input
              type="checkbox"
              class="checkbox"
              v-model="blur"
              @change="func_change_blur"
            />
            <div class="knobs-cov">
              <span></span>
            </div>
            <div class="layer-cov"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    anim: false,
    blur: false,
  }),
  methods: {
    func_change_blur() {
      let user_state = this.USER_STATE;
      user_state.blur = !this.blur;
      this.$store.commit("CHANGE_USER", user_state);

      let url = "order.php/save_blur_user";
      let param = {
        blur: this.blur ? 0 : 1,
      };
      this.$sendRequest(url, param).then();
    },
    func_change_anim() {
      let anim = !this.anim;
      if (anim) {
        window.bubbly();
        document.querySelector("body").classList.add("trans");
      } else {
        let canvas_all = document.querySelectorAll("canvas");
        if (!canvas_all) return;
        canvas_all.forEach((item) => {
          document.querySelector("body").removeChild(item);
        });
        document.querySelector("body").classList.remove("trans");
      }

      let user_state = this.USER_STATE;
      user_state.anim = anim;
      this.$store.commit("CHANGE_USER", user_state);

      let url = "order.php/save_anim_user";
      let param = {
        anim: anim ? 1 : 0,
      };
      this.$sendRequest(url, param).then();
    },
  },
  mounted() {
    if (this.USER_STATE) {
      this.anim = !this.USER_STATE.anim;
      this.blur = !this.USER_STATE.blur;
    }
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style></style>
